import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { v4 as uuid } from 'uuid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import FormHelperText from '@mui/material/FormHelperText';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { object, mixed } from 'yup';
import { Formik, Form } from 'formik';
import { setError } from "../slices/appSlice";
import FileInput from '../components/form/fileInput';
import axios from 'axios';

const s3ImagePrefix = () => {
    if (window.location.hostname === 'replacements.lifetimebrands.com') {
        return 'production';
    } else {
        return 'test';
    }
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 9,
    borderRadius: 5,
    width: '50%',
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: 'primary'
    },
}));

const FILE_SIZE = 10 * 1024 * 1024;
const postValidationSchema = () =>
  object().shape({
    postImg: mixed()
      .nullable()
      .test('fileSize', "Image is too large", value => {
        if (!value) {
          return true;
        }
        return value.size < FILE_SIZE;
      }),
  });

export default function ImageUploader({ title, imageArray, imageKeyArray, imageIndex, setImageArray, item_id }) {
    const dispatch = useDispatch();
    const [postImg, setPostImg] = useState(imageArray[imageIndex]);
    const [imageError, setImageError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [progressInfo, setProgressInfo] = useState("Upload");

    useEffect(() => {
        setPostImg(imageArray[imageIndex])
    }, [imageArray, imageIndex]);

    const handleDeleteImage = () => {
        const index = imageArray.indexOf(postImg);
        const newImages = [...imageArray];
        const newImageKeys = [...imageKeyArray];
          if (index > -1) {
            newImages.splice(index, 1);
            newImageKeys.splice(index, 1);
          }
          setImageArray({
              images: newImages,
              imageKeys: newImageKeys,
              item_id: item_id
          });
    }

    const handleUploadImage = (image, key) => {
        const newImages = [...imageArray];
        const newImageKeys = [...imageKeyArray];
        if (imageIndex < newImages.length) {
            newImages[imageIndex] = image;
            newImageKeys[imageIndex] = key;
        } else if (imageIndex < 2){
            newImages.push(image);
            newImageKeys.push(key);
        }
        setImageArray({
            images: newImages,
            imageKeys: newImageKeys,
            item_id: item_id
        });
    }

    const id = `${item_id}_img_${imageIndex}`;

    const getPresignEndpointURL = () => {
      if (s3ImagePrefix() === 'production') {
        return 'https://9swlqqccu7.execute-api.us-east-2.amazonaws.com/production/presign';
      } else {
        return 'https://h7z6lfxcig.execute-api.us-east-2.amazonaws.com/test/presign';
      }
    }

    return (
        <Box sx={{ marginTop: 1, marginBottom: 4, width:"100%" }}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                paddingX={1}
            >
                <Box sx={{ fontWeight: 700, color: 'paragraph' }}>
                    {title}
                </Box>
                {postImg &&
                    <Box>
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                fontWeight: 'bold',
                                justifyContent: "center",
                                cursor: "pointer"
                            }}
                            onClick={handleDeleteImage}
                            color="warning.main"
                            underline="none">
                            <DeleteIcon
                                sx={{
                                    fontSize:"20px!important",
                                    paddingX: "10px",
                                    cursor: "pointer"
                                }}
                            />
                            Delete Image
                        </Box>
                    </Box>
                }
            </Box>
            <Formik
                initialValues={{
                postImg: null,
                post: '',
                }}
                validationSchema={postValidationSchema}>
                {formProps => (
              <Form>
                    <label htmlFor={id} className='card-img-top'>
                        {postImg ?
                            <Box
                                component="img"
                                sx={{
                                    marginTop: 2,
                                    border: "1px solid",
                                    borderColor: grey[300],
                                    borderRadius: '14px',
                                    width: '100%'
                                }}
                                alt={"Uploaded Image"}
                                src={postImg}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = require('../assets/images/default_image.png');
                                }}
                            /> :
                            <Box
                                sx={{
                                    marginTop: 2,
                                    align: 'justify',
                                    border: "2px dashed",
                                    borderColor: grey[300],
                                    borderRadius: '14px',
                                    backgroundColor: 'white',
                                    minHeight: '300px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                { loading ?
                                    <Box sx={{
                                        flexGrow: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center' }}>
                                        <Typography
                                            variant="body1"
                                            align="justify"
                                            fontSize={15}
                                            color="primary"
                                            marginBottom={1}
                                            sx={{
                                                display: "block"
                                            }}
                                        >
                                            {progressInfo}
                                        </Typography>
                                        <BorderLinearProgress
                                            variant="determinate"
                                            value={progress}
                                        />
                                    </Box> :
                                    <Box sx={{
                                        flexGrow: 1,
                                        display:'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center' }}>

                                        <BackupOutlinedIcon
                                            color="primary"
                                            sx={{
                                                fontSize:"45px!important"
                                            }}
                                        />
                                        <Typography
                                            variant="body1"
                                            align="justify"
                                            fontSize={15}
                                            color="primary"
                                            marginBottom={1}
                                            sx={{
                                                display: "block"
                                            }}
                                        >
                                            Upload image
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                        }
                        <FileInput
                            name={id}
                            display='none'
                            id={id}
                            accept='image/jpg, image/jpeg, image/png'
                            onChange={async e => {
                                const prevPostImage = postImg;
                                try {
                                    setLoading(true);
                                    setPostImg(null);
                                    setProgress(0);
                                    setProgressInfo("Uploading");
                                    dispatch(setError(null));
                                    const prefix = s3ImagePrefix();
                                    const data = e.target.files[0];
                                    const uniqAssetName = `${prefix}/${uuid()}-${data.name}`;
                                    const validFileExtensions = [".jpg", ".jpeg", ".png"];
                                    let blnValid = false;
                                    const sFileName = data.name;
                                    for (let j = 0; j < validFileExtensions.length; j++) {
                                        const sCurExtension = validFileExtensions[j];
                                        if (sFileName.toLowerCase().endsWith(sCurExtension)) {
                                            blnValid = true;
                                            break;
                                        }
                                    }
                                    if (data.size < FILE_SIZE && blnValid) {
                                        formProps.setFieldValue(id, data);

                                        // Step 1: Request presigned URL
                                        const presignEndpoint = getPresignEndpointURL();
                                        const presignResponse = await axios.post(presignEndpoint, {
                                            operation: "put",
                                            filename: uniqAssetName
                                        }, {
                                            headers: {
                                                'Content-Type': 'application/json'
                                            }
                                        });

                                        const { presigned_url: presignedUrl } = presignResponse.data;

                                        if (!presignedUrl) {
                                            setImageError('Presigned URL not received. Failed to upload image, please try again.');
                                            setPostImg(prevPostImage);
                                            setLoading(false);
                                            dispatch(setError(true));
                                            return;
                                        }

                                        // Step 2: Upload the file using the presigned URL
                                        await axios.put(presignedUrl, data, {
                                            headers: {
                                                'Content-Type': data.type,
                                            },
                                            onUploadProgress: (evt) => {
                                                setProgressInfo("Uploading...");
                                                const percentCompleted = Math.round((evt.loaded * 100) / evt.total);
                                                setProgress(percentCompleted);
                                            },
                                        });

                                        // Step 3: Update the UI with the uploaded image
                                        formProps.setFieldValue(id, data);
                                        const reader = new FileReader();
                                        reader.onload = () => {
                                            if (reader.readyState === 2) {
                                                setPostImg(reader.result);
                                                handleUploadImage(reader.result, uniqAssetName);
                                            }
                                        };
                                        reader.readAsDataURL(data);
                                        setImageError(null);
                                        setLoading(false);
                                        dispatch(setError(null));
                                    } else {
                                        setImageError("All images must be .JPEG, .JPG or .PNG with maximum file size of 10 MB.");
                                        setPostImg(prevPostImage);
                                        setLoading(false);
                                        dispatch(setError(true));
                                    }
                                } catch {
                                    setImageError("Failed to upload image, please try again");
                                    setPostImg(prevPostImage);
                                    setLoading(false);
                                    dispatch(setError(true));
                                }
                            }}
                        />
                    </label>
                    {imageError && (
                        <FormHelperText
                            id="outlined-weight-helper-text"
                            sx={{
                                color: 'warning.main'
                            }}
                        >
                            {imageError}
                        </FormHelperText>
                    )}
              </Form>
            )}
          </Formik>
        </Box>
    );
}
