import axios from 'axios';

const hostname = window.location.hostname;

var url = 'https://csrtest.lifetimebrands.com/api/replacements';

if (hostname === 'replacements.lifetimebrands.com') {
  url = 'https://csr.lifetimebrands.com/api/replacements'
} else if (hostname === 'localhost') {
  url = 'http://localhost:3000/api/replacements'
}

export function authHeader() {
  const headers = {
      'Content-Type': 'application/json'
  }
  return headers;
}

export default axios.create({
  baseURL: url,
  headers: authHeader(),
  withCredentials: false
});