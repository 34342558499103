import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { completeSubmission } from "../slices/appSlice";
import Subtitle from './subtitle';
import SubParagraph from './subParagraph';

export default function Submission() {
  const dispatch = useDispatch();
  const { steps, activeStep, loading, isCompleted, caseNumber, tryCount, isWholesale, isDtc, isCostco, brand, items, additionalInformation } = useSelector((state) => state.app);

  const dtcState = useSelector((state) => state.dtc);
  const wholesaleState = useSelector((state) => state.wholesale);

  const handleTryAgain = () => {
    if(steps.length === activeStep) {
      if(isDtc) {
        const submissionData = {
          test_mode: false,
          is_a_gift: dtcState.isGift,
          order_number: dtcState.orderNumber,
          replacements_url: window.location.href,
          email: dtcState.email,
          zipcode: dtcState.zipCode,
          items:
            items
            .filter(item => item.isSelected && (item.piecesDamaged !== 0 || item.piecesDamaged !== '') && item.images.length > 0)
            .map(item => ({ item_number: item.item_id, pieces_damaged: parseInt(item.piecesDamaged), images: item.imageKeys.map(key => key), problem: item.problemText}))
          ,
          additional_information: additionalInformation,
        }
        dispatch(completeSubmission(submissionData));
      } else if(isWholesale) {
        const data = new FormData();
        data.append('items',
          JSON.stringify(
            items
            .filter(item =>
              item.isSelected &&
              (item.piecesDamaged !== 0 || item.piecesDamaged !== '') &&
              item.images.length > 0 &&
              item.item_id.length >= 5)
            .map(item => ({ itemID: item.item_id, images: item.imageKeys, problemText: item.problemText, piecesDamaged: item.piecesDamaged}))
          )
        );
        data.append('additionalInformation', additionalInformation);
        wholesaleState.retailerSelected !== 'other' ?
          data.append('retailer', wholesaleState.retailerSelected) :
          data.append('retailer', wholesaleState.retailerName);
        data.append('additionalInformation', additionalInformation);
        data.append('personalInformation', JSON.stringify(wholesaleState.personalInformation));
        dispatch(completeSubmission(data));
      } else if(isCostco){
        const data = new FormData();
        data.append('items',
          JSON.stringify(
            items
            .filter(item =>
              item.isSelected &&
              (item.piecesDamaged !== 0 || item.piecesDamaged !== '') &&
              item.images.length > 0 &&
              item.item_id.length >= 5)
            .map(item => ({ itemID: item.item_id, images: item.imageKeys, problemText: item.problemText, piecesDamaged: item.piecesDamaged}))
          )
        );
        wholesaleState.retailerSelected !== 'other' ?
          data.append('retailer', wholesaleState.retailerSelected) :
          data.append('retailer', wholesaleState.retailerName);
        data.append('additionalInformation', additionalInformation);
        data.append('personalInformation', JSON.stringify(wholesaleState.personalInformation));
        dispatch(completeSubmission(data));
      }
    }
  }
  return (
    <Container
      maxWidth="md"
      sx={{
        px: 0
      }}
    >
      { isCompleted ?
        <>
          <TaskAltIcon
            sx={{
              color: 'submissionSuccess',
              display: "flex",
              width: "100%",
              alignItems: "center",
              fontWeight: 'normal!important',
              justifyContent: "center",
              fontSize:"60px!important",
            }}
          />
          <Subtitle>
            Submission Successful
          </Subtitle>
          <SubParagraph align="center" mt={4}>
            Thank you for providing the information for the
            replacement of your damaged item(s).
          </SubParagraph>
          <SubParagraph
            align="center"
          >
            We will review your request as soon as possible and
            if we require any additional
          </SubParagraph>
          <SubParagraph
            align="center"
          >
            information we will contact you at the email provided
            on the previous page.
          </SubParagraph>
          <SubParagraph
            align="center"
            mt={3}
            mb={2}
          >
            For any future questions regarding this replacement request
            please reference case number:
          </SubParagraph>
          <SubParagraph variant="h5" align="center" fontWeight={700} marginY={4} >
            {caseNumber}
          </SubParagraph>
          <SubParagraph align="center" mt={5} mb={2}>
            As always we appriciate you business and may find additional
            information by:
          </SubParagraph>
          <SubParagraph align="center" mt={5} mb={1}>
            <Link
              href={brand.homepage}
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                fontWeight: 'bold',
                justifyContent: "center"
              }}
              color="primary"
              underline="none"
              target="_blank"
              rel="noreferrer"
              mb={1}>
                <HomeOutlinedIcon
                  sx={{
                    fontSize:"25px!important",
                    paddingX: "10px"
                  }}
                />
                {brand.brand} Homepage
            </Link>
            <Link
              href={brand.faqs}
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                fontWeight: 'bold',
                justifyContent: "center"
              }}
              color="primary"
              underline="none"
              target="_blank"
              rel="noreferrer">
                <ListAltOutlinedIcon
                  sx={{
                    fontSize:"20px!important",
                    paddingX: "10px"
                  }}
                />
                Frequently Asked Questions
            </Link>
          </SubParagraph>
        </> :
        <>
          <HighlightOffIcon
            sx={{
              color: 'submissionFail',
              display: "flex",
              width: "100%",
              alignItems: "center",
              fontWeight: 'normal!important',
              justifyContent: "center",
              fontSize:"60px!important",
            }}
          />
          <Subtitle>
            Submission Unsuccessful
          </Subtitle>
          <SubParagraph
            align="center"
          >

          </SubParagraph>
          <SubParagraph
            align="center"
            mt={3}
            mb={4}
          >
             { tryCount<=300 &&
               <LoadingButton
                onClick={handleTryAgain}
                loading={loading}
                variant="contained"
                size="large"
                sx={{
                  textAlign:"center",
                  fontWeight: 700,
                  fontSize: 18,
                  paddingX: 4,
                  paddingY: "10px",
                  background: 'linear-gradient(to right, #4757D6, #8B9BFA)',
                  borderRadius: 50
                }}
              >
                Try Again
              </LoadingButton>
            }
          </SubParagraph>
          <SubParagraph
            align="center"
          >
            If you still have problems to submit your request, please contact us.
          </SubParagraph>
          <SubParagraph
            align="center"
            mt={4}
            mb={2}
          >
            <Button
              color="inherit"
              rel="noopener noreferrer"
              href={`mailto:${brand.email}`}
              variant="outlined"
              size="large"
              sx={{
                textAlign:"center",
                fontWeight: 700,
                fontSize: 18,
                paddingX: 4,
                paddingY: "10px",
                borderWidth: 3,
                borderStyle: 'solid',
                borderColor: "borderPrimary",
                color: "borderPrimary",
                borderRadius: 50
              }}
            >
              Contact Support
            </Button>
          </SubParagraph>
        </>
      }

    </Container>
  );
}
